<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Edit Tag</h3>
            </div>
            <CardToolbar
              @submitEvent="submit"
              back-text="Back to list"
              back-url="/tags/list"
              button-text="Update Tag"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="tag_form">
              <div class="form-group">
                <label for="tagTitle">Title</label>
                <input
                  v-model="tag.title"
                  id="tagTitle"
                  class="form-control form-control-solid"
                  placeholder="Enter Tag title"
                />
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  name: 'EditTag',
  components: {
    CardToolbar
  },
  data() {
    return {
      tag: {
        title: null
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Tags', route: '/tags/list' },
      { title: 'Edit' }
    ])

    ApiService.get(`/tags`, this.$route.params.id).then(({ data }) => {
      this.tag.title = data.title
    })
  },
  methods: {
    async submit() {
      this.patchTag(this.$route.params.id, this.tag).then(({ status }) => {
        if (status === 200) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Tag successful update in database'
          })

          setTimeout(() => {
            this.$router.push({ name: 'tags-list' })
          }, 500)
        }
      })
    },
    async patchTag(id, entity) {
      return await ApiService.update('/tags', id, entity)
    }
  }
}
</script>
